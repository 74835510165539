/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 90vh;
}

.email {
  margin-bottom: 4px;
  font-size: 14.88px;
  font-weight: 400;
  color: rgb(48, 49, 61);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.email input {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
}

.email input:focus {
  border-color: #0573e180;
  outline: 0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.hidden {
  display: none;
}

.container {
  max-width: 1300px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
  0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  padding: 40px;
  align-self: center;
  border-radius: 7px;
}

.section-card {
  margin: 20px 0;
}

.crossed {
  text-decoration: line-through;
}

.prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.small {
  font-size: 0.8em;
  padding: 5px 0;
}

.flex {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.img-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
}

.gray {
  color: #7c8fb1;
}

.t-img {
  display: flex;
  justify-content: space-around;
  height: 200px;
}

.m-a-s {
  margin-right: 20px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

img {
  max-width: 260px;
  max-height: 200px;
}

.img-small {
  width: 50px;
  display: none;
}

input, select, .in {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 10px;
  margin: 1px;
  width: 100%;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.bottom-section {
  flex:1;
  display: flex;
  align-items: flex-end;
}

.payment-container {
  width: 45%;
  padding: 0 15px;
  margin-top: 20px;
}

.info-container {
  width: 55%;
}

@media only screen and (max-width: 900px) {
  body {
    height: unset;
  }

  .payment-container { 
    margin-top: 0;
  }

  .info-container, .payment-container {
    width: 100%;
    padding: 0!important;
  }

  .container {
    flex-wrap: wrap;
    padding: 20px;
  }

  .flex {
    margin: 0;
    flex-direction: column;
  }

  .img-wrapper {
    order: 1;
    width: 100%;
  }
  .prices {
    order: 2;
  }

  img {
    max-width: 100%;
    max-height: 215px;
  }
  .prices .small {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .prices {
    margin-top: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  h3 {
    margin: 10px 0;
  }
}
